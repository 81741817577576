<template>
  <el-dialog
    title="新增訂閱方案"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="left" :model="formData" :rules="formRules" class="form">
      <BaseElFormItem prop="type">
        <BaseElSelect v-model="formData.type">
          <BaseElSelectOption
            v-for="option in createSubscribePlanTypeConfig"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.type === 'copy'" prop="plan">
        <BaseElSelect v-model="formData.plan">
          <BaseElSelectOption
            v-for="option in subscribePlanList"
            :key="option.id"
            :value="option.id"
            :label="option.name"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="onConfirm">
          確認
        </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { noEmptyRules } from '@/validation'
import { createSubscribePlanTypeConfig } from '@/config/subscribe'
import { useSubscribe } from '@/use/useSubscribe'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'CreateSubscribePlanDialog',
  setup (props, { emit }) {
    const { getSubscribePlan, subscribePlanList } = useSubscribe()
    const formRef = ref(null)
    const formData = reactive({
      type: 'create',
      plan: null,
    })
    const formRules = {
      plan: [noEmptyRules()],
    }
    const onConfirm = () => {
      formUtils.checkForm(formRef.value).then((valid) => {
        console.log('checkForm', valid)
        if (!valid) {
          return
        }
        if (formData.type === 'create') {
          emit('confirm', 'create')
        } else {
          emit('confirm', 'copy', formData.plan)
        }
      })
    }
    onMounted(async () => {
      await getSubscribePlan()
    })
    return {
      createSubscribePlanTypeConfig,
      formRef,
      formData,
      formRules,
      onConfirm,
      subscribePlanList,
    }
  },
})
</script>

<style scoped lang="postcss">
.form {
  @apply grid gap-[8px] grid-cols-2;
}
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
