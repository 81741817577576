<template>
  <div class="external-transaction-activity-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="訂閱方案名稱" prop="name" align="center" />
      <BaseElTableColumn label="進行中的訂單數量" prop="orderCount" align="center" width="100" />
      <BaseElTableColumn label="訂閱品項" prop="equityName" align="center" />
      <BaseElTableColumn label="單次訂閱時長" prop="duration" align="center" width="140" />
      <BaseElTableColumn label="單次訂閱期數" prop="period" align="center" width="140" />
      <BaseElTableColumn label="每期扣款金額" prop="amount" align="center" width="140" />
      <BaseElTableColumn label="合約" prop="enabled" align="center" width="140">
        <template slot-scope="scope">
          <Tag :type="selectedStatus(get(scope.row, 'hasContract'), 'tagType')">{{ selectedStatus(get(scope.row, 'hasContract'), 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" align="center" fixed="right" width="140">
        <template slot-scope="scope">
          <!-- <TableEditBtnGroup
            :deleteDisabled="!!get(scope.row, 'orderCount')"
            @edit="onEdit(scope.row)"
            @delete="onDelete(scope.row)"
          /> -->
          <BaseElButton type="text" class="btn edit" @click="onEdit(scope.row)">編輯</BaseElButton>
          <BaseElButton v-if="!!get(scope.row, 'orderCount')" type="text" class="btn delete" disabled>
            <el-tooltip placement="bottom">
              <div slot="content">
                此方案有進行中的訂單（有會員正在訂閱），不可刪除
              </div>
              <span>刪除</span>
            </el-tooltip>
          </BaseElButton>
          <BaseElButton v-else type="text" class="btn delete" @click="onDelete(scope.row)">
            <span>刪除</span>
          </BaseElButton>
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteSubscribePlan"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import dayjs from '@/lib/dayjs'
import { get, map } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import Tag from '@/components/Tag/Tag.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { DeleteSubscribePlan } from '@/api/subscribe'
import { useRouter } from 'vue-router/composables'
import { subscribeContractSelectedConfig } from '@/config/subscribe'
import store from '@/store'

export default defineComponent({
  name: 'SubscribePlanTable',
  components: {
    EmptyBlock,
    Tag,
    DeleteDialog,
  },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
      subscribePlan: false,
    })
    const shopId = computed(() => store.getters.shop)
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, name, orderCount, planEquityPack, planContracts, installmentSettings, periodSettings } = item
        const duration = get(periodSettings, 'method') === 'monthly' ? '1個月' : `${get(periodSettings, 'fields.days')}天`
        // const period = get(periodSettings, 'method') === 'monthly' ? `${get(periodSettings, 'fields.periods')}期` : '-'
        const period = `${get(periodSettings, 'fields.periods')}期`
        return {
          id,
          name,
          orderCount,
          equityName: get(planEquityPack, 'equityPack.name') || '-',
          hasContract: !!planContracts.length,
          amount: get(installmentSettings, 'fields.amount') || '-',
          duration,
          period,
        }
      })
    })
    const selectedStatus = (status, attr) => {
      return get(subscribeContractSelectedConfig[status], attr)
    }
    const deleteSubscribePlan = async () => {
      const [, err] = await DeleteSubscribePlan({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const onEdit = (row) => {
      router.push({
        name: 'SubscribePlanEdit',
        params: {
          id: row.id,
        },
        query: {
          edit: true,
        },
      })
    }

    return {
      modal,
      displayData,
      get,
      dayjs,
      onEdit,
      onDelete,
      deleteSubscribePlan,
      selectedStatus,
      selectRow,
    }
  },
})
</script>

<style lang="postcss" scoped>
.edit {
  @apply text-action;
}
.delete:not([disabled]) {
  @apply text-danger;
}
</style>
